import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {TrainingGoals} from "../../models/TrainingGoals";

export default class TrainingGoalsAdd extends BaseAddEditComponent{
    constructor(props) {
        super(props);
        this.name="TrainingGoals"
        this.url="/admin/training-goals"
    }
    getEntity = () => {
        return TrainingGoals(this.context.constants)
    }
}