import React, {Component} from "react";
import {Col, Grid, Row, Table} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import ReactPaginate from 'react-paginate';
import Loader from "../Loader/Loader";
import FilterForm from "../Form/FilterForm";
import EditButton from "./EditButton";
import AddButton from "./AddButton";
import Button from "components/CustomButton/CustomButton.jsx";
import API from '@aws-amplify/api';
import DeleteButton from "./DeleteButton";
import defaults from "../../defaults";
import Papa from "papaparse";

let g = defaults.userGroups


class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pagination_meta: {
                page: 1,
                per_page: 20,
                total_pages: 0,
                total_items: 0
            },
            filterParams: {},
            isLoading: true,
            selectedFile: null,
            fileData: null,
        };

        let defaultGroups = [g.admin, g.editor];

        this.actionButtons = [
            (list, data_) => {
                return <EditButton
                    editPath={list.props.editPath}
                    id={data_.id}
                    allowedGroups={list.props.allowedEdit || defaultGroups}
                />

            },
            (list, data_) => {
                return <DeleteButton
                    id={data_.id}
                    handleRemove={list.handleRemove}
                    allowedGroups={list.props.allowedDelete || defaultGroups}
                />
            }
        ]

        this.headerButtons = [
            (list) => {
                return <AddButton
                    addPath={list.props.addPath}
                    allowedGroups={list.props.allowedAdd || defaultGroups}
                />
            }
        ]
    }

    prepareFileData = () => {
        this.state.fileData.map(row => {
            Object.keys(row).map((key) => {
                let value = row[key]
                if (typeof (value) == "string" && value.startsWith("[")) {
                    row[key] = JSON.parse(row[key])
                }
            })
        })
    }

    fileSelectedHandler = event => {
        this.state.selectedFile = event.target.files[0]
        Papa.parse(this.state.selectedFile, {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            worker: true,
            complete: (results) => {
                this.state.fileData = results.data
            }
        })
    }

    preparePayload = () => {
        if (this.state.selectedFile) {
            this.prepareFileData()
            let payloadBody = {"body": {"data": this.state.fileData}}
            if (!this.props.name.toLowerCase().includes("translations")) {
                return payloadBody
            }
            if (this.state.emailReport !== '' || this.state.emailReport !== undefined) {
                    payloadBody["body"]["email"] = this.state.emailReport
            }
            return payloadBody
        } else {
            alert("First you need to choose a file!");
        }
    };

    fileUploadHandler = () => {
        let payload = this.preparePayload()
        API.post("admin", this.props.uploadApiUrl, payload)
            .then(data => {
                this.props.handleClick(this.props.name + " successfully uploaded data", "success", "tr")
            })
            .catch(error => {
                this.props.handleClick(error.response.data.error || error.response.data.message, "error", "tr");
            });
    };

    fileUpdateHandler = () => {
       let payload = this.preparePayload()
        console.log(payload.body)
        if (payload.body.email) {
             API.patch("admin", this.props.updateApiUrl, payload)
            .then(data => {
                this.props.handleClick(this.props.name + " successfully updated data", "success", "tr")
            })
            .catch(error => {
                this.props.handleClick(error.response.data.error || error.response.data.message, "error", "tr");
            });
        }
    }

    componentDidMount() {
        this.getList(this.getAllQueryParams())
    }

    getList = params => {
        API.get('admin', this.props.listApiUrl, {'queryStringParameters': params}).then(data => {
            this.setState({
                data: data.items,
                isLoading: false,
                pagination_meta: data._meta
            });
        }).catch(error => {
            console.log(error);
        });
    };

    handlePageClick = page => {
        this.chooseNewPage(page['selected'] + 1);
    };

    handleFilterSubmit = data => {
        this.setState({filterParams: data}, () => {
            this.reloadData();
        });
    };
    chooseNewPage = page => {
        let new_pagination_meta = this.state.pagination_meta;
        new_pagination_meta['page'] = page;

        this.setState({
            pagination_meta: new_pagination_meta,
            isLoading: true
        }, () => {
            this.reloadData();
        });
    };

    reloadData = () => {
        this.setState({isLoading: true}, () => {
            this.getList(this.getAllQueryParams());
        });
    };

    getAllQueryParams = () => {
        return Object.assign({}, {
            page: this.state.pagination_meta.page,
            per_page: this.state.pagination_meta.per_page
        }, this.state.filterParams);
    };

    handleRemove = (id, event) => {
        API.del('admin', this.props.removeUrl + '/' + id)
            .then(data => {
                this.props.handleClick(this.props.name + " successfully removed", "success", "tr");
                this.chooseNewPage(1)
            }).catch(error => {
            this.props.handleClick(error.response.data.error || error.response.data.message, "error", "tr");
        });
    };

    filterActions = (list) => {
        return list.filter(
            i => this.props.userHasPermission(i(this, {}).props.allowedGroups)
        )
    }

    updateButton = () => {
        if (this.props.additionalUploaderType) {
            return <Button
                onClick={this.fileUpdateHandler}
                bsStyle="danger"
            >Update</Button>
        }
    }

    emailFormReport = () => {
        if (this.props.additionalUploaderType) {
            return <form>
                <label>
                    <section>Enter email address for update report</section>
                    <div className="form-group">
                        <input value={this.emailReport} name="email" placeholder="Email" type="text"
                               className="form-control" onChange={this.handleEmailReportValue}/>
                    </div>
                </label>
            </form>
        }
    }

    handleEmailReportValue = (event) => {
        this.setState({emailReport: event.target.value});
    }

    render() {
        // Define
        let actionButtons = this.props.actions || this.actionButtons
        let headerButtons = this.props.headerButtons
            || (this.props.addPath ? this.headerButtons : [])

        // Filter
        let actionButtonsFiltered = this.filterActions(actionButtons)
        let headerButtonsFiltered = this.filterActions(headerButtons)

        // Disable Actions row and header buttons?
        let isDisableActions = this.props.disableActions || actionButtonsFiltered.length === 0
        let isDisableHeaderButtons = this.props.disableHeaderButtons || headerButtonsFiltered.length === 0

        let actionLayout = (this.props.model.length > 10 || actionButtonsFiltered.length > 2) ? "btn-group-sm btn-group-vertical" : "btn-toolbar"

        let filter;
        if (this.props.filterObject !== undefined) {
            filter = <FilterForm
                object={this.props.filterObject}
                onSubmit={this.handleFilterSubmit}
            />
        }

        let uploader;
        if (this.props.uploadApiUrl !== undefined) {
            uploader = (
                <Card
                    title={"Upload " + this.props.name}
                    content={
                        <div className="clearfix">
                            {this.emailFormReport()}
                            <div className="float-container"
                                 style={{height: 100, float: "left"}}>
                                <input type="file" accept={this.props.uploadAcceptType}
                                       onChange={this.fileSelectedHandler}
                                       className="form-group"/>
                                <Button onClick={this.fileUploadHandler}
                                        bsStyle="warning">Upload</Button>
                                {this.updateButton()}
                                <Button href={this.props.instructionUrl} target="_blank"
                                        bsStyle="success">Documentation</Button>


                            </div>
                        </div>
                    }/>
            )
        }
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={this.props.name + " List"}
                                content={
                                    <div>
                                        {isDisableHeaderButtons ? undefined : headerButtonsFiltered.map(
                                            (component, index) => {
                                                return React.cloneElement(
                                                    component(this),
                                                    {key: index}
                                                );
                                            })}
                                        {filter}
                                    </div>
                                }
                            />
                            {uploader}
                            <Card
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <Loader isLoading={this.state.isLoading}>
                                        <Table striped hover>
                                            <thead>
                                            <tr>
                                                {this.props.model.map((prop, key) => {
                                                    return <th
                                                        key={key}>{prop.col}</th>;
                                                })}
                                                {
                                                    isDisableActions ? undefined :
                                                        <th>Actions</th>
                                                }
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.data.map((data, index) => {
                                                    return <tr key={index}>
                                                        {this.props.model.map((prop, index) => {
                                                            return <td
                                                                key={index}>
                                                                {prop.normalizer === undefined
                                                                    ? (data[prop.row] === null || data[prop.row] === undefined)
                                                                        ? '-' : data[prop.row]
                                                                    : prop.normalizer(data)
                                                                }
                                                            </td>;
                                                        })}
                                                        {isDisableActions ? undefined :
                                                            <td className="table-actions">
                                                                <div
                                                                    className={actionLayout}
                                                                    role="group"
                                                                    aria-label="Action Buttons">
                                                                    {actionButtonsFiltered
                                                                        .map((component, index) => {
                                                                            component = component(this, data)
                                                                            if (component !== undefined) {
                                                                                return React.cloneElement(
                                                                                    component,
                                                                                    {key: index}
                                                                                )
                                                                            }
                                                                            return undefined
                                                                        })
                                                                    }
                                                                </div>
                                                            </td>}
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </Table>
                                        {this.state.pagination_meta && <Row>
                                            <Col md={12}>
                                                <ReactPaginate
                                                    previousLabel={'previous'}
                                                    nextLabel={'next'}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={this.state.pagination_meta.total_pages}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={'pagination'}
                                                    forcePage={this.state.pagination_meta.page - 1}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                />
                                            </Col>
                                        </Row>}
                                    </Loader>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default List;
