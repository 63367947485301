import Exercise from "./controllers/Exercise/Exercise.jsx";
import ExerciseAdd from "./controllers/Exercise/ExerciseAdd.jsx";
import ExerciseEdit from "./controllers/Exercise/ExerciseEdit";
import Info from "./controllers/Info";
import UserToken from "./controllers/User/UserToken";
import ExerciseTranslation from "./controllers/Translation/Exercise/Exercise";
import ExerciseTranslationAdd from "./controllers/Translation/Exercise/ExerciseAdd";
import ExerciseTranslationEdit from "./controllers/Translation/Exercise/ExerciseEdit";
import BodyPartTranslationAdd from "./controllers/Translation/BodyPart/BodyPartAdd";
import BodyPartTranslation from "./controllers/Translation/BodyPart/BodyPart";
import BodyPartTranslationEdit from "./controllers/Translation/BodyPart/BodyPartEdit";
import defaults from "./defaults";
import Workout from "./controllers/Workout/Workout";
import WorkoutAdd from "./controllers/Workout/WorkoutAdd";
import WorkoutEdit from "./controllers/Workout/WorkoutEdit";
import WorkoutBlockListEdit from "./controllers/Workout/Block/WorkoutBlockListEdit";
import Block from "./controllers/Block/Block";
import BlockAdd from "./controllers/Block/BlockAdd";
import BlockEdit from "./controllers/Block/BlockEdit";
import Tag from "./controllers/Tag/Tag";
import TagAdd from "./controllers/Tag/TagAdd";
import TagEdit from "./controllers/Tag/TagEdit";
import WorkoutTranslation from "./controllers/Translation/Workout/Workout";
import WorkoutTranslationAdd from "./controllers/Translation/Workout/WorkoutAdd";
import WorkoutTranslationEdit from "./controllers/Translation/Workout/WorkoutEdit";
import WorkoutTypeTranslation from "./controllers/Translation/WorkoutType/WorkoutType";
import WorkoutTypeTranslationAdd from "./controllers/Translation/WorkoutType/WorkoutTypeAdd";
import WorkoutTypeTranslationEdit from "./controllers/Translation/WorkoutType/WorkoutTypeEdit";
import WorkoutType from "./controllers/WorkoutType/WorkoutType";
import GenderWorkoutTypeAdd from "./controllers/WorkoutType/WorkoutTypesAdd";
import GenderWorkoutTypeAddEdit from "./controllers/WorkoutType/WorkoutTypesEdit";
import EquipmentGroupTranslation from "./controllers/Translation/EquipmentGroup/EquipmentGroup";
import EquipmentGroupTranslationAdd from "./controllers/Translation/EquipmentGroup/EquipmentGroupAdd";
import EquipmentGroupTranslationEdit from "./controllers/Translation/EquipmentGroup/EquipmentGroupEdit";
import EquipmentTranslation from "./controllers/Translation/Equipment/Equipment";
import EquipmentTranslationAdd from "./controllers/Translation/Equipment/EquipmentAdd";
import EquipmentTranslationEdit from "./controllers/Translation/Equipment/EquipmentEdit";
import BodyPartGroupTranslation from "./controllers/Translation/BodyPartGroup/BodyPartGroup";
import BodyPartGroupTranslationAdd from "./controllers/Translation/BodyPartGroup/BodyPartGroupAdd";
import BodyPartGroupTranslationEdit from "./controllers/Translation/BodyPartGroup/BodyPartGroupEdit";
import GenderBodyPartGroup from "./controllers/GenderBodyPartGroup/GenderBodyPartGroup";
import GenderBodyPartGroupAdd from "./controllers/GenderBodyPartGroup/GenderBodyPartGroupAdd";
import GenderBodyPartGroupEdit from "./controllers/GenderBodyPartGroup/GenderBodyPartGroupEdit";
import GenderEquipmentGroup from "./controllers/GenderEquipmentGroup/GenderBodyPartGroup";
import GenderEquipmentGroupAdd from "./controllers/GenderEquipmentGroup/GenderBodyPartGroupAdd";
import GenderEquipmentGroupEdit from "./controllers/GenderEquipmentGroup/GenderBodyPartGroupEdit";
import UniversalAudioAdd from "./controllers/UniversalAudio/UniversalAudioAdd";
import UniversalAudioEdit from "./controllers/UniversalAudio/UniversalAudioEdit";
import UniversalAudio from "./controllers/UniversalAudio/UniversalAudio";
import TrainingGoals from "./controllers/TrainingGoals/TrainingGoals";
import TrainingGoalsAdd from "./controllers/TrainingGoals/TrainingGoalsAdd";
import TrainingGoalsEdit from "./controllers/TrainingGoals/TrainingGoalsEdit";
import TrainingGoalsTranslations from "./controllers/Translation/TrainingGoals/TrainingGoals";
import TrainingGoalsTranslationAdd from "./controllers/Translation/TrainingGoals/TrainingGoalsAdd";
import TrainingGoalsTranslationEdit from "./controllers/Translation/TrainingGoals/TrainingGoalsEdit";
import LinksChecker from "./controllers/LinksChecker/LinksChecker";
import ContentDownload from "./controllers/ContentDownload/ContentDownload";
import ContentUpload from "./controllers/ContentUpload/ContentUpload";
import ReviewersPassword from "./controllers/User/ReviewersPassword";
import ReviewersPasswordAdd from "./controllers/User/ReviewersPasswordAdd";
import ReviewersPasswordEdit from "./controllers/User/ReviewersPasswordEdit";
import GenderBodyPart from "./controllers/GenderBodyPart/GenderBodyPart";
import GenderBodyPartAdd from "./controllers/GenderBodyPart/GenderBodyPartAdd";
import GenderBodyPartEdit from "./controllers/GenderBodyPart/GenderBodyPartEdit";
import GenderEquipmentAdd from "./controllers/GenderEquipment/GenderEquipmentAdd";
import GenderEquipment from "./controllers/GenderEquipment/GenderEquipment";
import GenderEquipmentEdit from "./controllers/GenderEquipment/GenderEquipmentEdit";
import GenderBodyPartTranslationEdit from "./controllers/Translation/GenderBodyPart/GenderBodyPartEdit";
import GenderBodyPartTranslationAdd from "./controllers/Translation/GenderBodyPart/GenderBodyPartAdd";
import GenderBodyPartTranslation from "./controllers/Translation/GenderBodyPart/GenderBodyPart";
import GenderEquipmentTranslationEdit from "./controllers/Translation/GenderEquipment/GenderEquipmentEdit";
import GenderEquipmentTranslationAdd from "./controllers/Translation/GenderEquipment/GenderEquipmentAdd";
import GenderEquipmentTranslation from "./controllers/Translation/GenderEquipment/GenderEquipment";

let g = defaults.userGroups

const dashboardRoutes = [
    {
        exact: true,
        path: "/exercises",
        name: "Exercises",
        icon: "pe-7s-check",
        component: Exercise,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/exercises/add",
        name: "Add Exercise",
        icon: "pe-7s-check",
        component: ExerciseAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/exercises/edit/:id",
        name: "Edit Exercise",
        icon: "pe-7s-check",
        component: ExerciseEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    // GenderBodyPart
    {
        exact: true,
        path: "/gender-body-part",
        name: "Gender Body Part",
        component: GenderBodyPart,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/gender-body-part/add",
        name: "Gender Body Part",
        component: GenderBodyPartAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/gender-body-part/edit/:id",
        name: "Edit Gender Body Part",
        component: GenderBodyPartEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },

        // GenderBodyPartGroup
    {
        exact: true,
        path: "/gender-body-part-groups",
        name: "Gender Body Part Groups",
        component: GenderBodyPartGroup,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/gender-body-part-groups/add",
        name: "Gender Body Part Group",
        component: GenderBodyPartGroupAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/gender-body-part-groups/edit/:id",
        name: "Edit Gender Body Part Group",
        component: GenderBodyPartGroupEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    // GenderEquipment
    {
        exact: true,
        path: "/gender-equipments",
        name: "Gender Equipments",
        component: GenderEquipment,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/gender-equipments/add",
        name: "Gender Equipment",
        component: GenderEquipmentAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/gender-equipments/edit/:id",
        name: "Edit Gender Equipment",
        component: GenderEquipmentEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    // GenderEquipmentGroup
    {
        exact: true,
        path: "/gender-equipment-groups",
        name: "Gender Equipment Groups",
        component: GenderEquipmentGroup,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/gender-equipment-groups/add",
        name: "Gender Equipment Group",
        component: GenderEquipmentGroupAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/gender-equipment-groups/edit/:id",
        name: "Edit Gender Equipment Group",
        component: GenderEquipmentGroupEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },

    // Training Goals
    {
        exact: true,
        path: "/training-goals",
        name: "Training Goals",
        icon: "pe-7s-target",
        component: TrainingGoals,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/training-goals/add",
        name: "",
        component: TrainingGoalsAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/training-goals/edit/:id",
        name: "",
        component: TrainingGoalsEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },

    // WorkoutTypes

    {
        exact: true,
        path: "/gender-workout-types",
        name: "Workouts Types",
        icon: "pe-7s-bookmarks",
        component: WorkoutType,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/gender-workout-types/add",
        name: "Gender Workout Types",
        icon: "pe-7s-bookmarks",
        component: GenderWorkoutTypeAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/gender-workout-types/edit/:id",
        name: "Edit Gender Workout Types",
        icon: "pe-7s-bookmarks",
        component: GenderWorkoutTypeAddEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },

    // Workouts
    {
        exact: true,
        path: "/workouts",
        name: "Workouts",
        icon: "pe-7s-gym",
        component: Workout,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/workouts/add",
        name: "Workout Add",
        icon: "pe-7s-gym",
        component: WorkoutAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        invisible: true,
        path: "/workouts/edit/:id",
        name: "Workout Edit",
        icon: "pe-7s-gym",
        component: WorkoutEdit,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },
    {
        exact: true,
        invisible: true,
        path: "/workouts/:id/blocks",
        name: "Workout Blocks",
        icon: "pe-7s-gym",
        component: WorkoutBlockListEdit,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    // Blocks
    {
        exact: true,
        path: "/blocks",
        name: "Blocks",
        icon: "pe-7s-box2",
        component: Block,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/blocks/add",
        name: "Block Add",
        icon: "pe-7s-gym",
        component: BlockAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        invisible: true,
        path: "/blocks/edit/:id",
        name: "Block Edit",
        icon: "pe-7s-gym",
        component: BlockEdit,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },
    // Tags
    {
        exact: true,
        submenu: true,
        path: "/blocks/tags",
        name: "Tags",
        component: Tag,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/blocks/tags/add",
        name: "Tag Add",
        icon: "pe-7s-gym",
        component: TagAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        invisible: true,
        path: "/blocks/tags/edit/:id",
        name: "Tag Edit",
        icon: "pe-7s-gym",
        component: TagEdit,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },

    // Universal Audio
    {
        exact: true,
        path: "/universal-audio",
        name: "Universal-audio",
        icon: "pe-7s-musiclist",
        component: UniversalAudio,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/universal-audio/add",
        name: "Universal-audio Add",
        icon: "pe-7s-musiclist",
        component: UniversalAudioAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        invisible: true,
        path: "/universal-audio/edit/:id",
        name: "Universal-audio Edit",
        icon: "pe-7s-musiclist",
        component: UniversalAudioEdit,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor]
    },

    // Translations exercises
    {
        exact: true,
        path: "/translations/exercises",
        name: "Translations",
        icon: "pe-7s-pen",
        component: ExerciseTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        exact: true,
        submenu: true,
        path: "/translations/exercises",
        name: "Exercises",
        component: ExerciseTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/exercises/add",
        name: "",
        component: ExerciseTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/exercises/edit/:id",
        name: "",
        component: ExerciseTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },

        // Translations Workouts
    {
        exact: true,
        submenu: true,
        path: "/translations/workouts",
        name: "Workouts",
        component: WorkoutTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/workouts/add",
        name: "",
        component: WorkoutTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/workouts/edit/:id",
        name: "",
        component: WorkoutTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
        // Translations Workout Types
    {
        exact: true,
        submenu: true,
        path: "/translations/workout-types",
        name: "Workout Types",
        component: WorkoutTypeTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/workout-types/add",
        name: "",
        component: WorkoutTypeTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/workout-types/edit/:id",
        name: "",
        component: WorkoutTypeTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
        // Translations Equipment
    {
        exact: true,
        submenu: true,
        path: "/translations/equipments",
        name: "Equipment",
        component: EquipmentTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/equipments/add",
        name: "",
        component: EquipmentTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/equipments/edit/:id",
        name: "",
        component: EquipmentTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
       // Translations Gender Equipment
    {
        exact: true,
        submenu: true,
        path: "/translations/gender-equipments",
        name: "Gender Equipment",
        component: GenderEquipmentTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/gender-equipments/add",
        name: "",
        component: GenderEquipmentTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/gender-equipments/edit/:id",
        name: "",
        component: GenderEquipmentTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    // Translations Equipment groups

    {
        exact: true,
        submenu: true,
        path: "/translations/equipment-groups",
        name: "Equipment Groups",
        component: EquipmentGroupTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/equipment-groups/add",
        name: "",
        component: EquipmentGroupTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/equipment-groups/edit/:id",
        name: "",
        component: EquipmentGroupTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    // Translations Body parts
    {
        exact: true,
        submenu: true,
        path: "/translations/body-parts",
        name: "Body Parts",
        component: BodyPartTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/body-parts/add",
        name: "",
        component: BodyPartTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/body-parts/edit/:id",
        name: "",
        component: BodyPartTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    // Translations Gender Body parts
    {
        exact: true,
        submenu: true,
        path: "/translations/gender-body-parts",
        name: "Gender Body Parts",
        component: GenderBodyPartTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/gender-body-parts/add",
        name: "",
        component: GenderBodyPartTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/gender-body-parts/edit/:id",
        name: "",
        component: GenderBodyPartTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },

    // Translations Body Part Group

    {
        exact: true,
        submenu: true,
        path: "/translations/body-part-groups",
        name: "Body Part Groups",
        component: BodyPartGroupTranslation,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/body-part-groups/add",
        name: "",
        component: BodyPartGroupTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/body-part-groups/edit/:id",
        name: "",
        component: BodyPartGroupTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },

    // Translations TrainingGoals
    {
        exact: true,
        submenu: true,
        path: "/translations/training-goals",
        name: "Training Goals",
        component: TrainingGoalsTranslations,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer]
    },
    {
        path: "/translations/training-goals/add",
        name: "",
        component: TrainingGoalsTranslationAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        path: "/translations/training-goals/edit/:id",
        name: "",
        component: TrainingGoalsTranslationEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin, g.editor]
    },
    {
        exact: true,
        path: "/users/token",
        name: "User Token",
        icon: "pe-7s-user",
        component: UserToken,
        layout: "/admin",
        allowedGroups: [g.admin]
    },
    {
        exact: true,
        path: "/reviewers",
        name: "Reviewers Password",
        icon: "pe-7s-user",
        component: ReviewersPassword,
        layout: "/admin",
        allowedGroups: [g.admin]
    },
    {
        path: "/reviewers/add",
        name: "",
        icon: "pe-7s-user",
        component: ReviewersPasswordAdd,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin]
    },
    {
        path: "/reviewers/edit/:id",
        name: "",
        icon: "pe-7s-user",
        component: ReviewersPasswordEdit,
        layout: "/admin",
        invisible: true,
        allowedGroups: [g.admin]
    },
    {
        path: "/info",
        name: "Info",
        icon: "pe-7s-info",
        component: Info,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer, g.support]
    },

    // Links Checker
    {
        exact: true,
        path: "/linkchecker",
        name: "Links Checker",
        icon: "pe-7s-link",
        component: LinksChecker,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer, g.support]
    },
    // Content Download
    {
        exact: true,
        path: "/content-download",
        name: "Content Download",
        icon: "pe-7s-cloud-download",
        component: ContentDownload,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer, g.support]
    },
    // Content Download
    {
        exact: true,
        path: "/content-upload",
        name: "Content Upload",
        icon: "pe-7s-cloud-upload",
        component: ContentUpload,
        layout: "/admin",
        allowedGroups: [g.admin, g.editor, g.viewer, g.support]
    },

];

export default dashboardRoutes;
