import TrainingGoalsAdd from "./TrainingGoalsAdd";
import {TrainingGoals} from "../../models/TrainingGoals";
import React from "react";

export default class TrainingGoalsEdit extends TrainingGoalsAdd {
    constructor(props) {
        super(props);
        this.skipPrepopulation = true
    }
    render() {
        return React.cloneElement(
            super.render(),
            {
                md: 12,
                responseData: this.locationState().training_goal
            }
        )
    }
    getEntity = () => {
        return TrainingGoals(this.context.constants);
    }
}