import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {ExerciseModel} from "../../models/Exercise";


export default class ExerciseAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.state = {
            workouts: {},
        }
        this.name = "Exercise"
        this.url = "/admin/exercises"
        this.redirectUrl = "/admin/exercises"
    }


    getEntity = () => {
        return ExerciseModel(this.context.constants);
    }
}
