import {buildSelector, constToSelectOptions} from "../utils/Utils";

export const ExerciseKeys = [
    'body_part',
    'equipment',
    'exercise_level',
];

export const ExerciseModel = (constants) => {
    return {
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        preview: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        media_url: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },

        body_parts: buildSelector(constToSelectOptions(
            constants,
            'body_part'),
            'multiSelect'
        ),
        equipment: buildSelector(constToSelectOptions(
            constants,
            'equipment'),
            'multiSelect'
        ),
        exercise_level: buildSelector(constToSelectOptions(constants, 'exercise_level'), 'select'),
        exercise_type: buildSelector(constToSelectOptions(constants, 'exercise_type'), 'select'),

        base_pace: {
            inputType: 'number',
            validationRules: 'required',
            value: '',
            requestNormalizer: (data) => {
                return parseFloat(data);
            }
        },
        base_mets: {
            inputType: 'number',
            validationRules: 'required',
            value: '',
            requestNormalizer: (data) => {
                return parseFloat(data);
            }
        },
    };
}

export const ExerciseFilter = (constants) => {
    let model = {};

    for (const field_name of ExerciseKeys) {
        model[field_name] = buildSelector(constToSelectOptions(constants, field_name))
    }
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            },
        },
        name: {
            type: 'input',
            value: '',
        },
        ...model

    };
};