import React, {Component} from "react";
import List from "../../components/List/List";
import {TrainingGoalsFilter} from "../../models/TrainingGoals";
import {NavLink} from "react-router-dom";
import Button from "react-bootstrap/lib/Button";
import API from "@aws-amplify/api";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {constList} from "../../utils/Utils";
import defaults from "../../defaults";

let g = defaults.userGroups

export default class TrainingGoals extends Component{
    static contextType = ApiConstantsContext;

    constructor(props){
        super(props);
        this.addPath = "/admin/training-goals/add";
        this.editPath = "/admin/training-goals/edit";
        this.state = {
            key: 0
        }

    }
    setTrainingGoalsStatus = (event, goal_id, status) => {
        let button = event.target;
        button.disable = true;
        let url = `/admin/training-goals/${goal_id}/status`

        API.put('admin', url, {'body': {"is_published": status}})
            .then(data=> {
                this.props.handleClick(`Successfully set status to ${status}`, "success", "tr");
                this.setState({key: Math.random()});
            }).catch(error=>{
                this.props.handleClick("Failed to set status: " + JSON.stringify(error.response.data), "error", "tr");
        }).finally(() => {button.disable = false;})
    };
    render(){
        let constants = this.context.constants;
        let c = this.context.get_const;
        return (
            <List
                key={this.state.key}
                name="TrainingGoals"
                actions={[
                    (list, data) => {
                        return <NavLink
                            to={{
                                pathname: list.props.editPath + '/' + data.id,
                                state: {
                                    training_goal: data,
                                }
                            }}
                            className="btn btn-primary btn-fill"
                            >Edit</NavLink>
                    },
                    (list, data) => {
                        if (data.status === "soft_deleted") {
                            return undefined;
                        }
                        return <Button
                            allowedGroups={[g.admin, g.editor]}
                            className="btn btn-danger btn-fill"
                            onClick={(e) => list.handleRemove(data.id, e)}>
                            Delete
                        </Button>
                    },
                    (list, data) => {
                        if (data.status === "published") {
                            return <Button
                                className="btn btn-warning btn-fill"
                                onClick={(e) => this.setTrainingGoalsStatus(e, data.id, false)}
                            >
                                Unpublish
                            </Button>
                        } else {
                            return <Button
                                className="btn btn-info btn-fill"
                                onClick={(e) => this.setTrainingGoalsStatus(e, data.id, true)}
                            >
                                Publish
                            </Button>
                        }
                    }
                ]}
                addPath={this.addPath}
                editPath={this.editPath}
                listApiUrl="/admin/training-goals"
                removeUrl="/admin/training-goals"
                model={[
                    {col: "ID", row: "id"},
                    {col: "Name", row: "name"},
                    {
                        col: 'Preview', row: 'preview', normalizer: (data) => {
                            return <a href={data.preview} target={"blank"}>
                                <img alt={data.name}
                                     src={data.preview}
                                     width="150"/>
                            </a>
                        }
                    },
                    {col: 'Gender', row: 'gender'},
                    {
                        col: 'Required Workout Type',
                        row: 'required_workout_type',
                        normalizer: (data) => {
                            return constList(c, data, 'required_workout_type','workout_type')
                        }
                    },
                    {
                        col: 'Recommended Workout Type',
                        row: 'recommended_workout_type',
                        normalizer: (data) => {
                            if (data !== undefined){
                                return constList(c, data, 'recommended_workout_type', 'workout_type')
                            }
                        }
                    },
                    {
                        col: 'Is Default',
                        row: 'is_default',
                        normalizer: (data) => {
                            let is_default = 'no'
                            if (data.is_default === true){
                                is_default = "yes";
                            }
                            return is_default
                        }
                    },
                ]}
                handleClick={this.props.handleClick}
                filterObject={TrainingGoalsFilter(constants)}
                userHasPermission={this.props.userHasPermission}
            >
            </List>
        );
    }

}