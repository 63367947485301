import {buildSelector, constToSelectOptions, yesNoSelector} from "../utils/Utils";

export const TrainingGoals = constants => {
    return {
        name: {type: 'input', validationRules: 'required',value: ''},
        preview: {
            type: 'input',
            validationRules: ['', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        gender: buildSelector(constToSelectOptions(constants, 'gender'), 'select'),
        required_workout_type: buildSelector(constToSelectOptions(constants, 'workout_type'), 'multiSelect'),
        recommended_workout_type: {
            ...buildSelector(constToSelectOptions(constants, 'workout_type'), 'multiSelect'),
            validationRules: '',
            value: []

        },
        position: {
            validationRules: '',
            requestNormalizer: (data) => {
                return parseInt(data);
            }
        },
        is_default: yesNoSelector()

    }
}


export const TrainingGoalsFilter = constants => {
    return {
        training_goal_id: {
            type: 'input',
            value: '',
            placeholder: 'Id'
        },
        name: {
            type: 'input',
            value: '',
            placeholder: 'name'
        },
        gender: buildSelector(constToSelectOptions(constants, 'gender')),
        required_workout_type: buildSelector(constToSelectOptions(constants, 'workout_type')),
        recommended_workout_type: buildSelector(constToSelectOptions(constants, 'workout_type'))
    }
};