import React, {Component} from "react";
import List from "../../components/List/List";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {constToSelectOptions} from "../../utils/Utils";
import Button from "react-bootstrap/lib/Button";
import API from "@aws-amplify/api";
import {NavLink} from "react-router-dom";
import {GenderWorkoutTypeFilter} from "../../models/WorkoutType";


export default class WorkoutType extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/gender-workout-types/add';
        this.editPath = '/admin/gender-workout-types/edit';
        this.state = {
            key: 0
        }
    }

    setWorkoutTypeStatus = (event, id, status) => {
        let button = event.target;
        button.disabled = true;
        let url = `/admin/gender-workout-types/${id}/status`

        API.put('admin', url, {'body': {"is_published": status}})
            .then(data => {
                this.props.handleClick(`Successfully set status to ${status}`, "success", "tr");
                this.setState({key: Math.random()});
            }).catch(error => {
            this.props.handleClick("Failed to set status: " + JSON.stringify(error.response.data), "error", "tr");
        }).finally(() => {
                button.disabled = false;
            }
        );
    };

    render() {
        let constants = this.context.constants;
        let get_const = this.context.get_const;
        let workoutTypes = constToSelectOptions(constants, 'workout_type')

        return (
            <List
                key={this.state.key}
                name="WorkoutTypes"
                actions={[
                    (list, data) => {
                        return <NavLink
                            to={{
                                pathname: list.props.editPath + '/' + data.id,
                                state: {
                                    gender_workout_type: data,
                                }
                            }}
                            className="btn btn-primary btn-fill"
                        >
                            Edit
                        </NavLink>
                    },

                    (list, data) => {
                        if (data.status === "published") {
                            return <Button
                                className="btn btn-warning btn-fill"
                                onClick={(e) => this.setWorkoutTypeStatus(e, data.id, false)}
                            >
                                Unpublish
                            </Button>
                        } else {
                            return <Button
                                className="btn btn-info btn-fill"
                                onClick={(e) => this.setWorkoutTypeStatus(e, data.id, true)}
                            >
                                Publish
                            </Button>
                        }
                    }
                ]}
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl='/admin/gender-workout-types'
                listApiUrl='/admin/gender-workout-types'
                model={[
                    {
                        col: "Workout Type Id",
                        row: "workout_type_id"
                    },
                    {
                        col: 'Workout Type',
                        row: 'workout_type',
                        normalizer: (data) => {
                            return workoutTypes[data.workout_type]
                        }
                    },
                    {
                        col: 'Preview', row: 'preview', normalizer: (data) => {
                            return <a href={data.preview} target={"blank"}>
                                <img alt={data.name}
                                     src={data.preview}
                                     width="150"/>
                            </a>
                        }
                    },
                    {col: 'Gender', row: 'gender'},
                    {col: 'Levels', row: 'levels'},
                    {
                        col: 'Default Days',
                        row: 'default_days',
                        normalizer: (data) => {
                                return <ul>
                                    {data.default_days.map((k, index) => {
                                        return <li
                                            key={index}>{k}
                                        </li>
                                    })}
                                </ul>
                            }
                    },
                    {col: 'Min Workouts Per Week', row: 'min_workouts_per_week'},
                    {col: 'Max Workouts Per Week', row: 'max_workouts_per_week'},
                    {
                        col: 'Equipment Groups',
                        row: 'equipment_groups',
                        normalizer: (data) => {
                            return <ul>
                                {data.equipment_groups.map((k, index) => {
                                    return <li
                                        key={index}>{k}
                                    </li>
                                })}
                            </ul>
                        }
                    },
                    {
                        col: 'Body Part Groups',
                        row: 'body_part_groups',
                        normalizer: (data) => {
                            return <ul>
                                {data.body_part_groups.map((k, index) => {
                                    return <li
                                        key={index}>{k}
                                    </li>
                                })}
                            </ul>
                        }
                    },
                    {
                        col: 'Status',
                        row: 'status',
                        normalizer: (data) => {
                            let colors = {
                                "published": "green",
                                "not_published": "orange",
                                "soft_deleted": "red"
                            }
                            return <p
                                style={{color: colors[data.status] || "black"}}>{get_const("workout_status", data.status)}</p>
                        }
                    }
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={GenderWorkoutTypeFilter(constants)}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}
